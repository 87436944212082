module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cooperativa de Ahorro y Crédito Gestión para el desarrollo","short_name":"CoopGestión","start_url":"/","background_color":"#eaf3eb","theme_color":"#dee3e3","display":"minimal-ui","icon":"src/images/cg_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fea93afaf412cac79f5826432cb0ab44"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
